/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,300&display=swap'); */

:root {
    --color_dark: #1D1D1D;
    --color_white: #ffffff;
    --color_red: #c36;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2833, 37, 41, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}


.container-xxl{
    max-width: 1330px;
}


img {
    max-width: 100%;
}

body {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    min-height: 100vh;
    overflow-y:scroll;
    overflow-x:hidden;
}

a {
    text-decoration: none;
    color: var(--color_red);
}

header {
    min-height: 100px;
}

footer {
    background-color: var(--color_dark);
}
@media (max-width: 960px){
    ul.footer-menu{
        display: grid;
    }
}

footer li {
    list-style: none;
}

footer li a {
    color: var(--color_white);
}

.logos-gallery {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
}
.the-team-cards{
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 40px;
}
.the-team-cards .card {
    border: none;
    border-radius: unset;
}
.the-team-cards .card img {
    border-radius: unset;
}
.figure-img-wrap {
    overflow: hidden;
}
.figure .figure-img {
    transform: scale(1);
    transition: 1s;
    margin: 0;
}
.figure:hover .figure-img {
    transform: scale(1.3);
}
.figure .figure-caption{
    padding: 14px 2px 0;
}
.home_slide_caption_00 .carousel-caption {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 0 0 50px;
}
.home_slide_caption_0 .carousel-caption {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 0 0 50px;
    font-size: 70px;
    line-height: 1.2;
    word-break: break-all;
}
.carousel-control-next {
    right: 20px;
    justify-content: flex-end;
}
.carousel-control-prev {
    left: 20px;
    justify-content: flex-start;
}


.carousel.slide, .carousel-inner, .carousel-item, .carousel-item > img{
    height: 100%;
}
.slider-section{
    height: 36.5vw;
}
.aligncenter {
    text-align:center;
}
/* sm */
@media (min-width: 576px) {
    .logos-gallery {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
    .the-team-cards{
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

/* md */
@media (min-width: 768px) {
    .logos-gallery {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    
}

/* lg */
@media (min-width: 992px) {
    .logos-gallery {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    .the-team-cards{
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

/* xl */
@media (min-width: 768px) and (max-width: 1023px) {
    .home_slide_caption_1 .carousel-caption {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 100%;
        align-items: center;
        padding: 0;
        font-size: 50px;
        line-height: 1.2;
        word-break: break-all;
        bottom: 0;
    }
    .home_slide_caption_0 .carousel-caption {
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        height: 100%;
        align-items: center;
        padding: 0;
        font-size: 18px;
        line-height: 1.2;
        word-break: break-all;
        bottom: 20px;
    }
}

/* xxl */
@media (min-width: 1400px) {
    .logos-gallery {
        grid-template-columns: repeat(6, minmax(0, 1fr));
    }
}
@media (max-width: 1350px) {
    .card img, .logos-gallery img, .simple-page-featured-image, .logo-wrap > a > img{
        width: 100%;
        height: auto;
    }
}


input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
  }
  
  input[type=submit] {
    background-color: #04AA6D;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .edit-page{
    position: fixed;
    border: 1px solid red;
    top: 10px;
    left: 10px;
    padding: 5px;
    font-size: 10px;
  }

  .input-error{
    border: 2px solid red  !important;
  }

    .contact-msg{
        margin-top: 30px;
    }
    .contact-msg-error{
        margin-top: 30px;
        color:red;
    }

    .project-featured-image img{
        object-fit:cover;
        width:100%;
        max-height: 1000px;
        height: 39vw;
    }

    .video-frame {
        aspect-ratio: 16 / 9;box-sizing: border-box;
    }
    .video-frame iframe{
        height: 100%;
        width: 100%;
        display: flex;
        border: none;
        background-color: #000;
    }

    .video-tile-frame iframe{
        width: 100%;
        display: flex;
        border: none;
    }

    .container-xxl .project-content img{
        width: 100%;
        
    }
    

    @media (max-width: 1400px) {
        .video-tile-frame iframe{
            width: 100%;
            height: 100%;
        }
    }


    .image-frame-cover{
        width:100%;
        height:100%;
    }
    .image-frame-cover img{
        width:100%;
        height:100%;
        object-fit:cover;
    }

    .image-frame-cover-cropped-1 img{
        max-height:643px;
        object-fit:cover;
    }

    .embeded-frame{
        width:100%;
        height:100%;
        aspect-ratio: 1/1;
    }

    .embeded-frame .embeded-video{
        height: 100%;
        width: 100%;
        display: flex;
        border: none;

    }

    .footer-menu li{
        display:inline;
        margin:15px;
        position: relative;
    }
    /* .footer-menu li:after{
        content: "";
        width: 2px;
        background-color: #ffffff;
        height: 17px;
        position: absolute;
        top: 1px;
        right: -12px;
    }
    .footer-menu li:last-child:after{
        display:none;
    } */

    .iframe-creative-review{
        width:100%;
    }

    .w-trusted{
        aspect-ratio: 212/141;
    }


        @media (max-width: 991px) {

            header {
                min-height: 75px;
            }
    
            .navbar-toggler:focus {
                box-shadow: none;
            }
    
            .navbar-toggler {
                border: none;
            }
    
            .navbar.navbar-expand-lg {
                position: inherit;
            }
    
            div#navbarNav.collapse {
                transform: translateX(105%);
            }

            
            div#navbarNav {
                display: block;
                background: #ffffff;
                left: 0px;
                transform: translateX(0%);
                position: fixed;
                width: 100%;
                transition: 0.6s;
                height: 100%;
                margin-top: 10px;
                z-index: 999;
            }
    
            ul.navbar-nav {
                padding: 15px;
            }
    
            ul.navbar-nav li.nav-item {
                margin: 0 5px;
                padding: 0 10px;
            }
    
            span.navbar-toggler-icon {
                background-image:none;
                display: flex;
                justify-content: space-around;
                flex-direction: column;
            }
    
            span.navbar-toggler-icon:before,
            span.navbar-toggler-icon:after {
                content: " ";
                width: 30px;
                height: 2px;
                display: block;
                background: #222;
                margin: 5px 0;
                transition: 0.6s;
                transform: translateY(0px) rotate(0deg);
            }


            
            button.open span.navbar-toggler-icon:before {
                transform: translateY(8px) rotate(45deg);
            }

            button.open span.navbar-toggler-icon:after {
                transform: translateY(-7px) rotate(-45deg);
            }

 
        }


        @media (max-width:767px) {
            .carousel-control-prev, .carousel-control-next {
                display: none !important;
            }
            figure.figure {
                display: block;
            }

            .box .figure .figure-img {
                width: 100%;
                height: 360px;
                object-fit: cover;
            }
            .slider-section{
                height: 65vw;
            }
            .home_slide_caption_1 .carousel-caption {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: 100%;
                align-items: center;
                padding: 0;
                font-size: 29px;
                line-height: 1.2;
                bottom: 0;
            }
            .home_slide_caption_0 .carousel-caption {
                display: flex;
                justify-content: flex-end;
                flex-direction: column;
                height: 100%;
                align-items: center;
                padding: 0;
                line-height: 1.1;
                font-size: 12px;
                bottom: 7px;
            }

        }